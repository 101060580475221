<template>
  <yb-icon
    v-yb-clipboard:text="text"
    v-tooltip="'Copy to clipboard'"
    :class="classes"
    data-test-id="copy-clipboard-icon"
    icon="clipboard"
    :clipboard-label="label"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Function],
      required: false
    },
    wrapperClasses: {
      type: String,
      default: 'inline-block ml-2 mt-2'
    },
    iconClasses: {
      type: String,
      default: 'w-4 h-4'
    },
    label: String
  },
  computed: {
    classes() {
      return `${this.wrapperClasses} ${this.iconClasses} cursor-pointer`
    },
    text() {
      if (!this.value) {
        return ''
      } else if (typeof this.value === 'string') {
        return this.value
      } else if (typeof this.value === 'number') {
        return String(this.value)
      } else if (typeof this.value === 'function') {
        return this.value()
      } else {
        return '(unknown type: ' + typeof this.value + ')'
      }
    }
  }
}
</script>
